import React from "react";

import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import { stylesX, useStyle } from "./style";

interface ICardCommand {
  num: number;
  namePatient?: string;
  date: string;
  price: string;
  status: string;
  id: number;
}
const CardCommand: React.FC<ICardCommand> = (props) => {
  const { num, namePatient, date, price, status, id } = props;
  const navigate = useNavigate();
  const style = useStyle();
  return (
    <div className={style.container}>
      <div>
        <Typography sx={stylesX.colorTextNumber}>{`N°${num}`}</Typography>
        <div className={style.rootDetailsPersonnal}>
          <Typography sx={stylesX.name}>{namePatient}</Typography>
          <Typography>{date}</Typography>
        </div>
      </div>
      <div className={style.rootPrice}>
        <Typography sx={stylesX.price}>Prix:</Typography>
        <Typography>{price}</Typography>
      </div>
      <Button
        sx={{
          background: "#22A55B",
          width: "100%",
          padding: "10px 0 10px 0",
          display: "flex",
          flexDirection: "column",
        }}
        color="primary"
        onClick={() => navigate(`suivi/4/${id}`)}
      >
        <Typography sx={stylesX.colorStatus}>Statut:</Typography>
        <Typography sx={stylesX.typeStatus}>{status}</Typography>
      </Button>
    </div>
  );
};

export default CardCommand;
