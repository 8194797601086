export const stylesX = {
  container: {
    // padding: "20px",
    width: "100%",
    background: "#fff",
    borderTopLeftRadius: "42px",
    boxShadow: "0px -12px 6px #00000029",
    height: "100%",
    "@media (max-width: 508px)": {
      marginBottom: "45px",
    },
  },
  menu: {
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    justifyContent: "center",
  },
  label: {
    display: {
      md: "flex",
      xs: "none",
    },
    marginTop: "5px",
    textTransform: "capitalize",
    letterSpacing: 2,
  },
  box: {
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
};
