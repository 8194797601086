import React from "react";

import { useQuery } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import moment from "moment";
import { useNavigate } from "react-router-dom";

import CardCommand from "../../Components/CardCommand/CardCommand";
import {
  Query,
  QueryGetListCommandesArgs,
} from "../../graphql/__generated__/types";
import { GET_ORDER_BY_ID } from "../../graphql/command/query";
import { stylesX, useStyle } from "./style";

const HomePage: React.FC = () => {
  const style = useStyle();
  const navigate = useNavigate();
  const { data } = useQuery<Query, QueryGetListCommandesArgs>(GET_ORDER_BY_ID, {
    variables: {
      input: {
        take: 3,
        skip: 0,
        TypeCommandes: 4,
      },
    },
    fetchPolicy: "cache-and-network",
  });

  return (
    <div className={style.root}>
      <div className={style.boxChoiceButton}>
        <div style={{ padding: "0 55px 0px 55px" }}>
          <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
            Que souhaitez-vous faire ?
          </Typography>
          <div className={style.boxButton}>
            <Button
              className={style.buttonOrder}
              onClick={() => navigate("3/order")}
            >
              <Typography sx={stylesX.textCommand}>Commander</Typography>
            </Button>

            <Button
              className={style.buttonDevis}
              onClick={() => navigate("2/devis")}
            >
              <Typography sx={stylesX.textDevis}>Demander un devis</Typography>
            </Button>
          </div>
        </div>
        <div className={style.view}>
          <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
            Mes 03 dernières commandes
          </Typography>
          <div className={style.containerlistCard}>
            {data?.GetListCommandes?.map((command) => {
              const info = JSON.parse(command?.data);
              const price = info.price.find(
                (i: any) => i.label === "TTC conseillé",
              ).value;
              return (
                <CardCommand
                  key={command?.id}
                  date={moment(command?.datecommande).format("DD/MM/YYYY")}
                  num={command?.id || 0}
                  price={price}
                  status={info.status}
                  namePatient={info.Nom_du_patient}
                  id={command?.id || 0}
                />
              );
            })}
          </div>
        </div>
        <div className={style.helpBox}>
          <Button onClick={() => navigate("helpers")}>
            <p className={style.helptext}>Besoin d'aide</p>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
