import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  container: {
    background: "#ffffff",
    flex: "1",
    width: "100%",
    borderTopLeftRadius: "24px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
  },
  name: { fontSize: "18px", fontWeight: "600" },
  boxEdit: {
    position: "fixed",
    bottom: "15px",
    right: "23px",
    background: "#00b15fe8",
    borderRadius: "10px",
  },
});
