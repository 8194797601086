import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "Montserrat",
    fontSize: 12,
  },
  palette: {
    primary: {
      main: "#34c278",
      light: "#f2f2f2",
      dark: "#002884",
      contrastText: "#fff",
    },
    secondary: {
      light: "#ff7961",
      main: "#f44336",
      dark: "#ba000d",
      contrastText: "#00000075",
    },
  },
});

export default theme;
