import { ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import moment from "moment";
import "moment/locale/fr";
import "react-datepicker/dist/react-datepicker.css";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import "./App.css";
import { CreateClient } from "./Config/Client";
import Router from "./Container/Router/Router";
import { AuthProvider } from "./Context/AuthContext";
import ImageProvider from "./Provider/imageProvider";
import theme from "./Theme/theme";

moment.locale("fr");

const App = () => {
  const router = createBrowserRouter([{ path: "*", Component: Router }]);
  return (
    <div className="App">
      <AuthProvider>
        <ImageProvider>
          <CreateClient>
            <ThemeProvider theme={theme}>
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <RouterProvider router={router} />
              </LocalizationProvider>
            </ThemeProvider>
          </CreateClient>
        </ImageProvider>
      </AuthProvider>
    </div>
  );
};

export default App;
