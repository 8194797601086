import React from "react";

import { Outlet, useNavigate } from "react-router-dom";

interface IProtectedRoute {
  token: string;
  redirectPath: any;
}
const ProtectedRoute: React.FC<IProtectedRoute> = ({
  token,
  redirectPath = "/",
}: any) => {
  const navigate = useNavigate();

  React.useEffect(() => {
    const isAuthenticated = token !== null;

    if (!isAuthenticated) {
      navigate(redirectPath);
    }
  }, [token, redirectPath, navigate]);

  if (!token) navigate(redirectPath);
  return token ? <Outlet /> : null;
};

export default ProtectedRoute;
