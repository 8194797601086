import React from "react";

import { useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import ContentConfirmDialog from "../../Components/ContentConfirmDialog/ContentConfirmDialog";
import CustomAccordion from "../../Components/CustomAccordion/CustomAccordion";
import CustomModal from "../../Components/CustomModal/CustomModal";
import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import { dataSelect } from "../../Config/constant";
import {
  Mutation,
  MutationCreateHelperArgs,
} from "../../graphql/__generated__/types";
import { SEND_HELP } from "../../graphql/help/query";
import CustomSelect from "../Command/common/CustomSelect";
import { useStyles } from "./style";

interface IText {
  color: string;
  text: string;
}

interface IformHelper {
  description?: string;
  email: string;
  fistname: string;
  nature: string;
  numberphone: string;
}

const defaultValues: IformHelper = {
  description: "",
  email: "",
  fistname: "",
  nature: "",
  numberphone: "",
};
const Text: React.FC<IText> = ({ color, text }) => (
  <Typography color={color} fontSize="large">
    {text}
  </Typography>
);
const Help: React.FC = () => {
  const [visible, setVisible] = React.useState(false);
  const style = useStyles();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IformHelper>();

  const [sendHelp, { loading }] = useMutation<
    Mutation,
    MutationCreateHelperArgs
  >(SEND_HELP, {
    onCompleted: (data) => {
      if (data.CreateHelper === "SUCCESS") {
        setVisible((prev) => !prev);
        reset(defaultValues);
      }
    },
  });

  const onCompleted = (data: IformHelper) => {
    sendHelp({
      variables: {
        input: data,
      },
    });
  };

  return (
    <div className={style.container}>
      <Loading loading={loading} />
      <CustomModal
        open={visible}
        setOpen={setVisible}
        content={
          <ContentConfirmDialog onClick={() => setVisible((prev) => !prev)} />
        }
      />
      <CustomAccordion
        titleAccortions=" QUESTION SUR UNE COMMANDE ?"
        content={
          <div className={style.containerContaintAccordion}>
            <Text color="primary" text="  A-t-on bien reçu votre commande ?" />
            <Text
              color="primary"
              text=" Est-ce que la préparation est remboursée ?"
            />
            <Text
              color="primary"
              text="Vous avez constaté une erreur sur votre commande"
            />
            <Text
              color="error"
              text="Nous n’avons pas répondu à votre question ? Nous allons vous
              rappeler !"
            />
          </div>
        }
      />
      <CustomAccordion
        titleAccortions=" QUESTION DE COMPTABILITÉ ? "
        content={
          <div className={style.containerContaintAccordion}>
            <Text
              color="primary"
              text="Vous avez besoin d’un duplicata de facture ?"
            />
            <Text
              color="primary"
              text="Vous avez besoin d’un contrat de sous-traitance"
            />
            <Text
              color="error"
              text=" Nous n’avons pas répondu à votre question ? Nous allons vous
              rappeler !"
            />
          </div>
        }
      />
      <CustomAccordion
        titleAccortions=" QUESTION SUR DES DOSES OU LES RUPTURES ? "
        content={
          <div className={style.containerContaintAccordion}>
            <Text
              color="primary"
              text="Vous avez une question sur une dose pédiatrique ?"
            />
            <Text
              color="primary"
              text="Vous avez une question sur une dose vétérinaire ?"
            />
            <Text
              color="primary"
              text="Vous voulez savoir ce que nous pouvons réaliser en remplacement
              des ruptures ?"
            />
            <Text
              color="error"
              text="Nous n’avons pas répondu à votre question ? Nous allons vous
              rappeler !"
            />
          </div>
        }
      />
      <div style={{ padding: 20 }}>
        <Typography
          style={{
            alignSelf: "flex-start",
            display: "flex",
            fontWeight: "400",
            fontSize: "22px",
          }}
        >
          Demandez à être recontacter
        </Typography>

        <Controller
          name="fistname"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              error
              id="commande"
              label="Indiquez votre prénom pour le suivi *"
              value={value}
              onChange={onChange}
              helperText={errors.fistname?.message}
            />
          )}
        />

        <Controller
          name="email"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message: "Adresse email invalide",
            },
          }}
          render={({ field }) => (
            <CustomTextInput
              id="email"
              label="Votre adresse mail *"
              {...field}
              error
              helperText={errors.email?.message}
            />
          )}
        />

        <Controller
          name="numberphone"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis",
            },
            maxLength: {
              value: 10,
              message: "ce champ doit etre contenir 10 caracteres",
            },
            minLength: {
              value: 10,
              message: "ce champ doit etre contenir au moins 10 caracteres",
            },
          }}
          render={({ field }) => (
            <CustomTextInput
              error
              id="numberphone"
              label="Votre numero de téléphone *"
              type="number"
              {...field}
              helperText={errors.numberphone?.message}
            />
          )}
        />
        <Controller
          name="nature"
          control={control}
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis",
            },
          }}
          render={({ field: { onChange, value } }) => (
            <CustomSelect
              data={dataSelect}
              label="Nature"
              value={value}
              setValue={onChange}
              errors={!!errors.nature}
              errorLabel={errors.nature?.message}
            />
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <CustomTextInput
              id="desc"
              label="Description de votre besoin *"
              {...field}
            />
          )}
        />

        <Button
          size="large"
          onClick={handleSubmit(onCompleted)}
          style={{
            margin: "10px 0 0 0 ",
            alignSelf: "flex-start",
            display: "flex",
            fontWeight: "400",
            background: "#ffff",
            fontSize: "15px",
            boxShadow:
              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
          }}
        >
          <Typography>Contact moi</Typography>
        </Button>
      </div>
    </div>
  );
};

export default Help;
