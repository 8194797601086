/* eslint-disable no-nested-ternary */
import React, { useContext } from "react";

import { Button, Typography } from "@mui/material";
import { Outlet, useMatches, useParams } from "react-router-dom";

import logo from "../../Assets/img/logoBackgroundTransparent.png";
import image from "../../Assets/img/profil.png";
import { displayHeader } from "../../Config/constant";
import AuthContext from "../../Context/AuthContext";
import CustomButtonDashboard from "./Common/CustomButtonDashboard";
import { useStyle } from "./style";
import useDashboard from "./useDashboard";

const Dashboard: React.FC = () => {
  const { logout } = useContext(AuthContext);
  const { tableMenuLeft, navigate } = useDashboard();
  const { pathname }: any = useMatches().shift();
  const { type } = useParams();
  const style = useStyle();

  return (
    <div className={style.container}>
      <div className={style.rootLeft}>
        <div className={style.menuLeft}>
          <img src={logo} alt="logo" className={style.logo} />
          {tableMenuLeft.map((item) => {
            return (
              <CustomButtonDashboard
                key={item.name}
                icon={item.icon}
                name={item.name}
                onClick={item.onClick}
                background={item.path === pathname ? "#3A3A3A" : undefined}
                child={item.child}
              />
            );
          })}
        </div>
        <div>
          <Button
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
            }}
            onClick={() => navigate("profil")}
          >
            <img src={image} alt="profil" style={{ width: 113, height: 121 }} />
            <Typography>Mon Profil</Typography>
          </Button>
        </div>
        <Button onClick={logout} color="inherit">
          Deconnexion
        </Button>
      </div>
      <div
        className={style.rootRight}
        style={{ background: type === "2" ? "#056542" : "#22A55B" }}
      >
        {displayHeader(pathname)}
        <Outlet />
      </div>
    </div>
  );
};

export default Dashboard;
