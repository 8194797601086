import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    background: "0% 0% no-repeat padding-box padding-box rgb(255, 255, 255)",
    flex: "1 1 0%",
    width: "100%",
    borderRadius: "42px 0px 0px",
    boxShadow: "rgba(0, 0, 0, 0.16) 0px -12px 6px",
    padding: "42px 0 16px",
    overflow: "auto",
  },
  boxTitle: { textAlign: "left", padding: "0 0 50px 50px" },
  title: { fontSize: "24px", color: "#616161", fontWeight: "600" },
  boxContent: {
    flex: "1",
    display: "flex",
    height: "calc(100vh - 320px)",
    padding: "0 0 0 50px",
  },
  boxdetails: { flex: "1", padding: "0 50px 0 50px" },
  rootheader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    margin: "0 0 50px 0",
  },
  rootPrepdetails: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  rootFirstItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "flex-start",
  },
  rootSecondItem: {
    width: "100%",
    padding: "25px 0 25px 0",
    borderTop: "2px solid #F2F2F2",
    borderBottom: "2px solid #F2F2F2",
    marginTop: "25px",
  },
  rootDetailsSecondItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "20px 0",
  },
  rootThirdItem: {
    width: "100%",
    padding: "33px 0",
    display: "flex",
  },
  containerDateList: {
    display: "flex",
    width: "100%",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "flex-end",
  },
  rootListDateItem: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  itemTotal: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "25%",
  },
  listPrice: {
    display: "flex",
    justifyContent: "space-between",
    width: "100%",
    alignItems: "center",
  },
});

export const stylesX = {
  buttonStatus: {
    background: "#22A55B",
    color: "#fff",
    borderRadius: "6px",
    textTransform: "unset",
    fontSize: "16px",
  },
  validateButton: {
    textTransform: "unset",
    width: 167,
    backgroundColor: "#F9F9F9",
    fontSize: "16px",
    borderRadius: 6,
  },
  actionValidator: {
    textTransform: "unset",
    fontSize: "16px",
    borderRadius: 6,
    width: 167,
  },
  buttonBack: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    color: "rgb(34, 165, 91)",
    textTransform: "unset",
  },
};
