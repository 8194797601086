import React from "react";

import Logos from "../../Assets/img/logo.png";

const Logo: React.FC = () => {
  return (
    <img
      src={Logos}
      alt="logo"
      style={{ objectFit: "contain", width: "150px", height: "150px" }}
    />
  );
};

export default Logo;
