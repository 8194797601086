import * as React from "react";

import Button from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

interface ICustomModal {
  title?: React.ReactNode | JSX.Element;
  content: React.ReactNode | JSX.Element;
  cancelAction?: {
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    style?: React.CSSProperties | undefined;
  };
  confirmAction?: {
    text: string;
    onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
    style?: React.CSSProperties | undefined;
  };
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fullScreen?: boolean | undefined;
  dialogProps?: DialogProps;
}
const CustomModal: React.FC<ICustomModal> = (props) => {
  const {
    open,
    setOpen,
    title,
    content,
    cancelAction,
    confirmAction,
    fullScreen,
    dialogProps,
  } = props;

  const handleClose = () => setOpen((prev) => !prev);

  return (
    <div>
      <Dialog
        {...dialogProps}
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        fullScreen={fullScreen}
      >
        {title && <DialogTitle id="alert-dialog-title">{title}</DialogTitle>}

        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {content}
          </DialogContentText>
        </DialogContent>

        {(cancelAction || confirmAction) && (
          <DialogActions>
            <div
              style={{
                display: "flex",
                justifyContent: "space-around",
                alignItems: "center",
                width: "100%",
              }}
            >
              {cancelAction && (
                <Button onClick={handleClose} style={cancelAction.style}>
                  {cancelAction.text}
                </Button>
              )}
              {confirmAction && (
                <Button
                  onClick={confirmAction.onClick}
                  style={confirmAction.style}
                >
                  {confirmAction.text}
                </Button>
              )}
            </div>
          </DialogActions>
        )}
      </Dialog>
    </div>
  );
};
export default CustomModal;
