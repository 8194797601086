import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  container: { display: "flex", flexDirection: "row", minHeight: "100vh" },
  rootLeft: {
    width: "15%",
    display: "flex",
    flexDirection: "column",
    "@media (max-width:700px)": {
      display: "none",
    },
  },
  menuLeft: {
    flex: "1 1 0%",
    display: "flex",
    padding: "25px 0px 0px 0px",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  rootRight: {
    flex: "1 1 0%",
    alignItems: "flex-start",
    display: "flex",
    padding: "0px 0px 0px 34px",
    flexDirection: "column",
    "@media (max-width:700px)": {
      padding: 0,
    },
  },
  boxTitle: {
    width: "100%",
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  boxChoiceButton: {
    flex: "1 1 0%",
    backgroundColor: "rgb(255, 255, 255)",
    width: "100%",
    flexDirection: "row",
    borderRadius: "33px 0px 0px",
    padding: "55px 0px 0px 0px",
    display: "flex",
  },
  boxButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  buttonOrder: {
    backgroundColor: "#056542 !important",
    width: 313,
    height: 157,
    borderRadius: "17px !important",
    margin: "17px 0 27px 0 !important",
  },
  buttonDevis: {
    backgroundColor: "#0000002950 !important",
    width: 313,
    height: 157,
    borderRadius: "17px !important",
    boxShadow: "0px 3px 6px #00000029 !important",
  },
  containerinformation: {
    display: "flex",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "center",
    height: "100%",
  },
  containerlistCard: {
    margin: "17px 0px 27px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  view: {
    display: "flex",
    flex: "1",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    flexDirection: "column",
  },
  root: {
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    boxShadow: "0px -12px 6px #00000029",
    borderTopLeftRadius: 33,
  },
  helpBox: {
    position: "fixed",
    bottom: "0",
    right: "0",
    background: "#00b15f",
    borderTopLeftRadius: "16px",
    padding: "10px 12px 0px 17px",
  },
  helptext: { fontSize: "16px", color: "#fff", fontWeight: "bold" },
  logo: {
    width: "100px",
    height: "100px",
    alignSelf: "center",
    margin: "0px 0 15px 0",
    objectFit: "contain",
  },
});

export const stylesX = {
  textCommand: { fontSize: 18, color: "#FFF", fontWeight: "bold" },
  textDevis: { fontSize: 18, color: "#056542", fontWeight: "bold" },
};
