/* eslint-disable no-nested-ternary */
import { useNavigate, useParams } from "react-router-dom";

import ChatIcon from "../../Assets/svg/menu/ChatIcon";
import CommandIcon from "../../Assets/svg/menu/Command";
import DevisIcon from "../../Assets/svg/menu/Devis";
import FactureIcon from "../../Assets/svg/menu/FactureIcon";
import HomeIcon from "../../Assets/svg/menu/Home";
import SuiviIcon from "../../Assets/svg/menu/Suivi";
import InfoIcon from "../../Assets/svg/menu/features/InfoIcon";

const sizeIcon = { width: 30, height: 30, stroke: "black" };

const useDashboard = () => {
  const navigate = useNavigate();
  const { activ, id } = useParams();
  const link = !activ
    ? ""
    : id
    ? `/${activ}/${id}`
    : !id
    ? `/${activ}`
    : `/${activ}`;

  const tableMenuLeft = [
    {
      id: 1,
      name: "Home",
      icon: <HomeIcon {...sizeIcon} {...{ color: "red" }} />,
      onClick: () => navigate(""),
      path: "/dashboard",
      child: undefined,
    },
    {
      id: 2,
      name: "Commande",
      icon: <CommandIcon {...sizeIcon} />,
      onClick: () => navigate("3/order"),
      child: undefined,
      path: "/dashboard/3/order",
    },
    {
      id: 3,
      name: "Devis",
      icon: <DevisIcon {...sizeIcon} />,
      onClick: () => navigate("2/devis"),
      child: undefined,
      path: "/dashboard/2/devis",
    },
    {
      id: 4,
      name: "Suivi",
      icon: <SuiviIcon {...sizeIcon} />,
      onClick: () => navigate("suivi"),
      path: `/dashboard/suivi${link}`,
      child: [
        {
          label: "Devis et commandes envoyés",
          onclick: () => navigate("/dashboard/suivi/3"),
          activID: "3",
        },
        {
          label: "Hist. des Devis",
          activID: "2",
          onclick: () => navigate("/dashboard/suivi/2"),
        },
        {
          activID: "4",
          label: "Hist. des Commandes",
          onclick: () => navigate("/dashboard/suivi/4"),
        },
        {
          label: "Plannification",
          activID: "1",
          onclick: () => navigate("/dashboard/suivi/1"),
        },
      ],
    },
    {
      id: 5,
      name: "Infos Ruptures",
      icon: <InfoIcon width={28} height={sizeIcon.height} color="#616161" />,
      onClick: () => navigate("controle"),
      child: undefined,
      path: "/dashboard/controle",
    },
    {
      id: 6,
      name: "Contact",
      icon: (
        <ChatIcon
          style={{
            height: "42px",
            width: "28px",
            color: "#616161",
          }}
        />
      ),
      onClick: () => navigate("contact"),
      child: undefined,
      path: "/dashboard/contact",
    },
    {
      id: 8,
      name: "Factures",
      icon: <FactureIcon width={28} height={sizeIcon.height} color="#616161" />,
      onClick: () => navigate("fact"),
      child: undefined,
      path: "/dashboard/fact",
    },
  ];
  return { tableMenuLeft, navigate };
};

export default useDashboard;
