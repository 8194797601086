import React from "react";

import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { Button, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import EmptyIcon from "../../Assets/svg/Background/EmptyIcon";
import { stylesX, useStyles } from "./style";

const Empty: React.FC = () => {
  const navigate = useNavigate();
  const style = useStyles();
  return (
    <div className={style.container}>
      <div>
        <Button sx={stylesX.buttonBack} onClick={() => navigate(-1)}>
          <NavigateBeforeIcon />
          Retour
        </Button>
      </div>
      <div className={style.root}>
        <EmptyIcon opacity={0.2} />
        <Typography style={{ color: "#909090" }}>
          informations introuvable
        </Typography>
      </div>
    </div>
  );
};

export default Empty;
