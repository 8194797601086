import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    width: "100%",
    height: "calc(100vh - 172px)",
    display: "flex",
    padding: "0 0 0 -1px",
    overflow: "scroll",
    background: "white",
    alignItems: "flex-start",
    flexDirection: "column",
    justifyContent: "flex-start",
    borderTopLeftRadius: "20px",
    boxShadow: "0px -12px 6px #00000029",
  },
  containerContaintAccordion: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
  },
});
