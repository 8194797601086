import React from "react";

import { Button } from "@mui/material";
import { useParams } from "react-router-dom";

import { stylesX } from "./style";
import { useStyles } from "./styles";

interface ICustomButtonDashboard {
  icon: any;
  name: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  background?: string;
  child?: {
    label: string;
    onclick: () => void;
    activID: string;
  }[];
}
const CustomButtonDashboard: React.FC<ICustomButtonDashboard> = ({
  icon,
  onClick,
  name,
  background = "#616161",
  child,
}) => {
  const { activ } = useParams();
  const style = useStyles();
  return (
    <div style={{ width: "100%" }}>
      <Button sx={[stylesX.container]} onClick={onClick}>
        {icon}
        <p
          className={style.text}
          style={{
            color: background,
            fontWeight: background === "#616161" ? "normal" : "bold",
          }}
        >
          {name}
        </p>
      </Button>
      {child && (
        <div className={style.btnChild}>
          {child.map((item) => {
            return (
              <Button
                onClick={item.onclick}
                style={{
                  fontSize: "10px",
                  lineHeight: "10px",
                  textAlign: "start",
                  textTransform: "unset",
                  color: activ === item.activID ? background : "#616161",
                  fontWeight: activ === item.activID ? "bold" : "normal",
                }}
              >
                {item.label}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default CustomButtonDashboard;
