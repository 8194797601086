import React from "react";

import { useLazyQuery } from "@apollo/client";
import CloseIcon from "@mui/icons-material/Close";
import { Button, Skeleton, Typography } from "@mui/material";
import moment from "moment";
import Iframe from "react-iframe";

import CustomModal from "../../Components/CustomModal/CustomModal";
import { Query } from "../../graphql/__generated__/types";
import { GET_ALL_FACTURES } from "../../graphql/factures/query";
import { useStyles } from "./style";

const Factures: React.FC = () => {
  const style = useStyles();
  const [openModal, setopenModal] = React.useState(false);

  const [getFactures, { data, loading }] = useLazyQuery<Query>(
    GET_ALL_FACTURES,
    {
      fetchPolicy: "cache-first",
    },
  );

  React.useEffect(() => {
    getFactures();
  }, [getFactures]);

  return (
    <div className={style.container}>
      {!loading &&
        data?.GetListFactures?.map((item, index) => {
          return (
            <div
              className={style.containerCard}
              style={{
                background: index % 2 === 0 ? "#F2F2F2" : "#fff",
                borderTopLeftRadius: index === 0 ? 21 : undefined,
              }}
            >
              <CustomModal
                open={openModal}
                setOpen={setopenModal}
                dialogProps={{
                  open: openModal,
                  onClose: setopenModal,
                  PaperProps: {
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  },
                }}
                content={
                  <div style={{}}>
                    <div className={style.containerIframe}>
                      <Button
                        size="large"
                        className={style.btnClose}
                        onClick={() => setopenModal((prev) => !prev)}
                      >
                        <CloseIcon />
                      </Button>
                    </div>
                    <Iframe
                      loading="auto"
                      url={item?.url || ""}
                      styles={{
                        display: "flex",
                        flex: "1",
                        width: "100%",
                        height: "95vh",
                      }}
                      id=""
                      className=""
                      display="block"
                      position="relative"
                    />
                  </div>
                }
                fullScreen
              />
              <div
                style={{
                  flex: "1 1 0%",
                  display: "flex",
                  justifyContent: "space-around",
                  alignItems: "center",
                }}
              >
                <Typography>
                  {moment(item?.date_facture).format("Do MMM YYYY")}
                </Typography>
                <Typography>{`n°${item?.nom_facture}`}</Typography>
              </div>
              <div style={{ width: "15%" }}>
                <Button onClick={() => setopenModal((prev) => !prev)}>
                  Voir
                </Button>
              </div>
            </div>
          );
        })}
      {loading && (
        <>
          <Skeleton width="100%" height={100} />
          <Skeleton width="100%" height={100} />
          <Skeleton width="100%" height={100} />
        </>
      )}
    </div>
  );
};

export default Factures;
