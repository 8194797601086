/* eslint-disable react/prop-types */
import { ReactNode } from "react";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Typography,
} from "@mui/material";

interface ICustomAccordion {
  titleAccortions: string;
  content: JSX.Element | ReactNode;
}
const CustomAccordion: React.FC<ICustomAccordion> = (props) => {
  const { titleAccortions, content } = props;
  return (
    <Accordion style={{ margin: "20px 20px 0 20px", minWidth: "50%" }}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Typography style={{ fontSize: 18, fontWeight: "bold" }}>
          {titleAccortions}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>{content}</AccordionDetails>
    </Accordion>
  );
};

export default CustomAccordion;
