/* eslint-disable no-nested-ternary */
import React from "react";

import {
  ApolloCache,
  FetchResult,
  useLazyQuery,
  useMutation,
  useQuery,
} from "@apollo/client";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import {
  AppBar,
  Button,
  Divider,
  Skeleton,
  Toolbar,
  Typography,
} from "@mui/material";
import moment from "moment";
import { useNavigate, useParams } from "react-router-dom";

import CustomModal from "../../../Components/CustomModal/CustomModal";
import Empty from "../../../Components/EmptyData/Empty";
import Loading from "../../../Components/Loading/Loading";
import { SetTitleByPathName, TypePatient } from "../../../Config/constant";
import {
  Mutation,
  MutationDeleteRenouvellementArgs,
  MutationUpdateValidateDevisArgs,
  Query,
  QueryGetListCommandesArgs,
  QueryResendCommandArgs,
} from "../../../graphql/__generated__/types";
import { DELETE_RENEWAL_ORDER } from "../../../graphql/command/mutation";
import { DO_RENEWAL, GET_ORDER_BY_ID } from "../../../graphql/command/query";
import { UPDATE_VALIDATE_DEVIS } from "../../../graphql/devis/mutation";
import CustomField from "../Common/Feature/CustomField";
import { stylesX, useStyles } from "./style";

const DetailsHistory: React.FC = () => {
  const style = useStyles();
  const { activ, id } = useParams();
  const navigate = useNavigate();
  const [openModal, setOpenModal] = React.useState(false);
  const [openModalDelete, setOpenModalDelete] = React.useState(false);
  const [openValidate, setopenValidate] = React.useState(false);

  const { data, loading, refetch } = useQuery<Query, QueryGetListCommandesArgs>(
    GET_ORDER_BY_ID,
    {
      variables: {
        input: {
          skip: 0,
          take: 1,
          TypeCommandes: Number(activ) || 3,
          search: id,
        },
      },
      fetchPolicy: "cache-first",
    },
  );

  const [SendRenewCommand, { loading: loadRenewal }] = useLazyQuery<
    Query,
    QueryResendCommandArgs
  >(DO_RENEWAL);

  const [UpdateValidation, { loading: loadingUpdateValidate }] = useMutation<
    Mutation,
    MutationUpdateValidateDevisArgs
  >(UPDATE_VALIDATE_DEVIS);

  const [DeleteOrder, { loading: loadingDelete }] = useMutation<
    Mutation,
    MutationDeleteRenouvellementArgs
  >(DELETE_RENEWAL_ORDER);

  const OnConfirmDelete = () => {
    setOpenModalDelete((prev) => !prev);
    DeleteOrder({
      variables: {
        input: {
          id: Number(id),
        },
      },
      update: (cache) => {
        const allQuery = cache.readQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              skip: 0,
              take: 50,
              TypeCommandes: 1,
            },
          },
        });

        const dataUpdated = allQuery?.GetListCommandes?.filter(
          (i) => i?.id !== Number(id),
        );
        const count = allQuery?.GetListCommandes?.length;
        const taken = count ? (count >= 50 ? count : 50) : 0;
        cache.writeQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              skip: 0,
              take: taken,
              TypeCommandes: 1,
            },
          },
          data: {
            GetListCommandes: dataUpdated,
          },
        });
        navigate(-1);
      },
    });
  };

  const onUpdateValidation = () => {
    UpdateValidation({
      variables: {
        input: {
          orderId: Number(id),
        },
      },
      update: (
        cache: ApolloCache<any>,
        result: Omit<FetchResult<Mutation>, "context">,
      ) => {
        const allQuery = cache.readQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              take: 50,
              skip: 0,
              TypeCommandes: Number(activ),
            },
          },
        });

        const dataUpdated = allQuery?.GetListCommandes?.map((i) => {
          if (i?.id === id) {
            return {
              ...result.data?.UpdateValidateDevis,
            };
          }
          return {
            ...i,
          };
        });
        cache.writeQuery<Query, QueryGetListCommandesArgs>({
          query: GET_ORDER_BY_ID,
          variables: {
            input: {
              take: 50,
              skip: 0,
              TypeCommandes: Number(activ),
            },
          },
          data: {
            GetListCommandes: dataUpdated,
          },
        });
        refetch();
      },
    });
  };

  const onRenewal = () => {
    SendRenewCommand({
      variables: {
        input: {
          id: Number(id),
        },
      },
      onCompleted: (res: Query) => {
        if (res.ResendCommand) {
          setopenValidate(true);
        }
      },
    });
  };

  return (
    <div className={style.container}>
      <div className={style.boxTitle}>
        <Loading
          loading={loadingDelete || loadingUpdateValidate || loadRenewal}
        />
        <CustomModal
          open={openValidate}
          setOpen={setopenValidate}
          title="Demande envoyé"
          content={
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography>
                Votre demande de renouvellement a bien été prise en compte.
              </Typography>
            </div>
          }
          cancelAction={{
            text: "Valider",
            onClick: () => {
              setopenValidate(false);
              navigate(-1);
            },
          }}
        />
        <Typography
          style={{ fontSize: "24px", color: "#616161", fontWeight: "600" }}
        >
          {SetTitleByPathName(activ || "3")}
        </Typography>

        {activ === "1" && (
          <Typography style={{ color: "#909090" }}>
            Vous devez renseigner la(es) date(s) à la(aux)quelle(s) nous
            recevrons votre (vos) demande(s)
          </Typography>
        )}
      </div>
      {loading && (
        <>
          <Skeleton height={150} />
          <Skeleton height={150} />
          <Skeleton height={150} />
        </>
      )}
      {!loading &&
        data?.GetListCommandes &&
        data?.GetListCommandes?.map((command) => {
          if (command === null) {
            return null;
          }
          const info = JSON.parse(command.data);
          const field = info.fieldname
            ? info.fieldname
            : info.type_commande === 2
            ? "Devis n°"
            : "Commande n°";
          const startDateRenouvellement = command?.renouvellement?.date;
          const rulesRenouvellement =
            command?.renouvellement?.rrule &&
            JSON.parse(command?.renouvellement?.rrule);
          const endDateRenouvellement =
            rulesRenouvellement?.dateRepeatList?.slice(-1);
          const listDatePlanification = rulesRenouvellement?.dateRepeatList;

          return (
            <div className={style.boxContent} key={command?.id}>
              <CustomModal
                open={openModalDelete}
                setOpen={setOpenModalDelete}
                title="Annulations d'un renouvellement"
                content={
                  <div>
                    <Typography>
                      êtes vous sûr de vouloir annuler le renouvellement ?
                    </Typography>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "flex-end",
                        padding: "10px 0",
                        textTransform: "unset",
                      }}
                    >
                      <Button
                        style={{
                          marginRight: 5,
                          textTransform: "unset",
                          fontSize: 14,
                        }}
                        variant="contained"
                        color="error"
                        size="large"
                        onClick={() => setOpenModalDelete((prev) => !prev)}
                      >
                        Annuler
                      </Button>
                      <Button
                        style={{ textTransform: "unset", fontSize: 14 }}
                        variant="contained"
                        color="primary"
                        size="large"
                        onClick={OnConfirmDelete}
                      >
                        Confirmer
                      </Button>
                    </div>
                  </div>
                }
              />
              <CustomModal
                open={openModal}
                setOpen={setOpenModal}
                content={
                  <div
                    style={{
                      width: "100%",
                      height: "90vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <AppBar
                      sx={{
                        position: "fixed",
                        top: 0,
                        background: "#00000075",
                      }}
                    >
                      <Toolbar
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "flex-end",
                        }}
                      >
                        <Button
                          style={{
                            backgroundColor: "#FFF",
                            padding: 10,
                            marginRight: 10,
                            boxShadow:
                              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
                          }}
                        >
                          save
                        </Button>
                        <Button
                          style={{
                            backgroundColor: "#FFF",
                            padding: 10,
                            boxShadow:
                              "0 3px 1px -2px rgba(0,0,0,.2), 0 2px 2px 0 rgba(0,0,0,.14), 0 1px 5px 0 rgba(0,0,0,.12)",
                          }}
                          onClick={() => setOpenModal((prev) => !prev)}
                        >
                          <Typography>Fermer</Typography>
                        </Button>
                      </Toolbar>
                    </AppBar>
                    <img
                      src={command?.img || ""}
                      alt="devis"
                      style={{
                        width: "50%",
                        objectFit: "contain",
                        height: "80%",
                      }}
                    />
                  </div>
                }
                fullScreen
                dialogProps={{
                  open: openModal,
                  onClose: setOpenModal,
                  PaperProps: {
                    style: {
                      backgroundColor: "transparent",
                      boxShadow: "none",
                    },
                  },
                }}
              />
              <div>
                <Button sx={stylesX.buttonBack} onClick={() => navigate(-1)}>
                  <NavigateBeforeIcon />
                  Retour
                </Button>
              </div>
              <div className={style.boxdetails}>
                <div className={style.rootheader}>
                  <Typography
                    style={{
                      color: "#616161",
                      fontSize: "25px",
                      fontWeight: "600",
                    }}
                  >
                    {`${field} : ${command.id}`}
                  </Typography>
                  {activ === "4" && info.status === "Libérée" && (
                    <Button sx={stylesX.buttonStatus} onClick={onRenewal}>
                      Renouveler
                    </Button>
                  )}
                  {command.img && (
                    <Button
                      sx={stylesX.buttonStatus}
                      onClick={() => setOpenModal((prev) => !prev)}
                    >
                      Voir mon document
                    </Button>
                  )}
                  {activ === "2" &&
                    (info?.isValidated ? (
                      <Button
                        size="large"
                        variant="contained"
                        disabled
                        sx={stylesX.validateButton}
                      >
                        Validé
                      </Button>
                    ) : (
                      <Button
                        size="large"
                        variant="contained"
                        color="primary"
                        sx={stylesX.actionValidator}
                        onClick={onUpdateValidation}
                      >
                        Valider le devis
                      </Button>
                    ))}
                </div>
                <div className={style.rootPrepdetails}>
                  <div className={style.rootFirstItem}>
                    <CustomField
                      label="Date du création"
                      value={moment(command?.datecommande).format(
                        "Do MMM YYYY",
                      )}
                    />

                    {info?.status && (
                      <CustomField
                        label="Statut de la commande"
                        value={info?.status}
                      />
                    )}
                    {startDateRenouvellement && activ === "1" && (
                      <CustomField
                        label="Différée"
                        value={moment(startDateRenouvellement).format(
                          "Do MMM YYYY",
                        )}
                      />
                    )}
                    {endDateRenouvellement && activ === "1" && (
                      <CustomField
                        label="Fin du renouvellement"
                        value={moment(new Date(endDateRenouvellement)).format(
                          "Do MMM YYYY",
                        )}
                      />
                    )}
                  </div>
                  <Divider />
                  <div className={style.rootSecondItem}>
                    <CustomField
                      label="Nom du prescritpeur"
                      value={info?.Nom_du_Medecin || ""}
                    />
                    <div className={style.rootDetailsSecondItem}>
                      <CustomField
                        label="Nom du patient"
                        value={info?.Nom_du_patient || ""}
                      />
                      <CustomField
                        label="Type de patient"
                        value={
                          TypePatient.find(
                            (type) => type.id === info?.Type_de_patient,
                          )?.name || "."
                        }
                      />
                      <CustomField
                        label="Forme galénique"
                        value={info?.Description || ""}
                      />
                    </div>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <CustomField
                        label="Formule de la commande"
                        value={info?.Indications || ""}
                      />
                      <CustomField label="Commentaire" value={info?.comment} />
                    </div>
                  </div>

                  <div className={style.rootThirdItem}>
                    {listDatePlanification && activ === "1" && (
                      <div className={style.containerDateList}>
                        <div className={style.rootListDateItem}>
                          <Typography
                            style={{ color: "#909090", fontSize: "12px" }}
                          >
                            Date du renouvellement
                          </Typography>
                          <div
                            style={{ display: "flex", flexDirection: "column" }}
                          >
                            {listDatePlanification?.map((date: string) => {
                              return <Typography>{date}</Typography>;
                            })}
                          </div>
                        </div>
                        <div>
                          <Button
                            color="error"
                            size="large"
                            variant="contained"
                            onClick={() => setOpenModalDelete((prev) => !prev)}
                          >
                            Supprimer
                          </Button>
                          <Button
                            style={{ margin: "0 25px" }}
                            variant="contained"
                            color="primary"
                            size="large"
                            onClick={() => navigate("edit")}
                          >
                            Modifier
                          </Button>
                        </div>
                      </div>
                    )}
                    {info?.price && (
                      <div
                        style={{
                          width: "100%",
                          display: "flex",
                          alignItems: "flex-start",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        <Typography>Total</Typography>
                        <div className={style.itemTotal}>
                          {info?.price?.map((item: any) => {
                            return (
                              <div className={style.listPrice} key={item.label}>
                                <Typography>{item.label}:</Typography>
                                <Typography>{item.value}</Typography>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          );
        })}

      {!loading && !data?.GetListCommandes?.length && <Empty />}
    </div>
  );
};

export default DetailsHistory;
