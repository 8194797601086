import React from "react";

import { Typography } from "@mui/material";

interface ICustomField {
  label: string;
  value: String | undefined;
}
const CustomField: React.FC<ICustomField> = (props) => {
  const { label, value } = props;
  return (
    <div
      style={{ display: "flex", width: "100%", padding: "21px 0px 20px 0px" }}
    >
      <Typography
        style={{
          fontSize: "18px",
          color: "#00b15f",
          fontWeight: "800",
          padding: "0 15px 0 15px",
        }}
      >
        {label}
      </Typography>
      <Typography style={{ fontWeight: "600" }}>{value}</Typography>
    </div>
  );
};

export default CustomField;
