import React from "react";

import { useLazyQuery } from "@apollo/client";
import { Box, Button, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import { FaLock } from "react-icons/fa";

import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import {
  Query,
  QueryGeneratorOtpArgs,
} from "../../graphql/__generated__/types";
import { GENERATOR_OTP } from "../../graphql/resetPassword/query";
import { stylesx } from "./style";

interface FormValues {
  email: string;
}

const defaultValues: FormValues = {
  email: "",
};

const Forgotpassword: React.FC = () => {
  // const { navigate } = useNavigation();

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<FormValues>();

  const [SendOtp, { loading }] = useLazyQuery<Query, QueryGeneratorOtpArgs>(
    GENERATOR_OTP,
    {
      onCompleted: (data) => {
        const idUser = data.GeneratorOtp?.id;
        if (idUser) {
          // navigate("resetPassword", { idUser });
          reset(defaultValues);
        }
      },
    },
  );

  const OnPressSendOTP = (data: FormValues) =>
    SendOtp({
      variables: {
        input: data,
      },
    });
  return (
    <Box sx={stylesx.box}>
      <Loading loading={loading} />
      <FaLock size={100} color="#d4d6d5" />
      <Typography style={{ marginBottom: 20, fontWeight: "500" }}>
        Mot de passe oublié ?
      </Typography>
      <Typography>
        Veuillez entrer votre adresse e-mail pour réinitialiser votre mot de
        passe.
        <Typography>
          Un code de réinitialisation vous sera envoyé dans votre boîte e-mail.
        </Typography>
      </Typography>
      <Controller
        control={control}
        render={({ field: { onChange, value } }) => (
          <CustomTextInput
            id="E-mail *"
            label="E-mail *"
            fullWidth={false}
            style={{ width: "50%" }}
            onChange={onChange}
            error={!!errors.email}
            value={value}
            helperText={errors.email?.message}
          />
        )}
        name="email"
        rules={{
          required: {
            value: true,
            message: "Please enter a valid email address",
          },
          pattern: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
        }}
        defaultValue=""
      />

      <Button
        variant="contained"
        color="primary"
        onClick={handleSubmit(OnPressSendOTP)}
      >
        Recherche
      </Button>
    </Box>
  );
};
export default Forgotpassword;
