import React, { useContext } from "react";

import { useMutation } from "@apollo/client";
import { Button, Typography } from "@mui/material";
import { DatePicker, MobileTimePicker } from "@mui/x-date-pickers";
import { Controller, useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import CustomModal from "../../Components/CustomModal/CustomModal";
import CustomTextInput from "../../Components/CustomTextInput/CustomTextInput";
import Loading from "../../Components/Loading/Loading";
import AuthContext from "../../Context/AuthContext";
import {
  Mutation,
  MutationCreateContactArgs,
} from "../../graphql/__generated__/types";
import { CONTACT } from "../../graphql/contact/mutation";

interface IFormContact {
  date: Date;
  email: string;
  heure: Date;
}

const defaultState: IFormContact = {
  date: new Date(),
  email: "",
  heure: new Date(),
};

const Contact: React.FC = () => {
  const { Me } = useContext(AuthContext);
  const info = [
    { label: "Address", value: Me?.client.adresse },
    { label: "Téléphone", value: Me?.client.liste_telephone_client[0].numero },
    { label: "Fax", value: Me?.client.liste_telephone_client[1].numero },
  ];

  const {
    control,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<IFormContact>({ defaultValues: defaultState });
  const navigate = useNavigate();
  const [open, setOpen] = React.useState(false);
  const [date, setdate] = React.useState<Date>();
  const [Time, setTime] = React.useState<Date>();
  const [commentaire, setcommentaire] = React.useState("");

  const [DoCreateContact, { loading }] = useMutation<
    Mutation,
    MutationCreateContactArgs
  >(CONTACT, {
    onCompleted: (result) => {
      if (result.CreateContact?.message === "SUCCESS") {
        reset(defaultState);
        setOpen(true);
      }
    },
  });

  const onValideContact = (data: IFormContact) => {
    DoCreateContact({
      variables: {
        input: { ...data, date, heure: Time, commentaire },
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flex: "1",
        background: "#FFFFFF",
        width: "100%",
        borderRadius: "42px 0px 0px 0px",
        padding: "70px 0 0 0px",
        boxShadow: "0px -12px 6px #00000029",
        flexDirection: "column",
        alignItems: "flex-start",
        overflow: "hidden",
      }}
    >
      <Loading loading={loading} />
      <CustomModal
        open={open}
        setOpen={setOpen}
        title="Demande de Contact"
        content={
          <Typography>
            Merci pour votre demande !{"\n"}Nous avons bien reçu votre message
            et nous vous recontacterons dans les plus brefs délais.
          </Typography>
        }
        confirmAction={{
          text: "Valider",
          onClick: () => {
            setOpen(false);
            navigate(-1);
          },
        }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          alignItems: "center",
          width: "100%",
          margin: "0 0 0 34px",
          padding: "0 0",
        }}
      >
        {info.map((i) => {
          return (
            <div
              style={{
                width: "20%",
                display: "flex",
                flexDirection: "column",
                alignItems: "start",
              }}
            >
              <Typography style={{ fontSize: "18px", fontWeight: "600" }}>
                {i.label}
              </Typography>
              <Typography style={{ fontSize: "14px" }}>{i.value}</Typography>
            </div>
          );
        })}
      </div>
      <Typography
        style={{ margin: "38px 0 0 34px", fontSize: "22px", fontWeight: "700" }}
      >
        Vous souhaitez nous contacter
      </Typography>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          margin: "20px 0 0 35px",
        }}
      >
        <Controller
          control={control}
          render={({ field: { onChange, value } }) => (
            <CustomTextInput
              error={!!errors.email}
              helperText={errors?.email?.message}
              label="Email*"
              placeholder="Votre adresse mail *"
              InputProps={{ style: { height: 55 } }}
              value={value}
              onChange={onChange}
            />
          )}
          name="email"
          rules={{
            required: {
              value: true,
              message: "Ce champ est requis.",
            },
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Ce champ doit être une adresse email valide",
            },
          }}
          defaultValue=""
        />

        <DatePicker
          label="Date"
          format="DD-MM-YYYY"
          sx={{ margin: "0 10px 0 10px" }}
          onChange={(newValue: any) => setdate(new Date(newValue))}
        />
        <MobileTimePicker
          label="Debut de la visite"
          onChange={(newValue: any) => setTime(new Date(newValue))}
        />
      </div>
      <CustomTextInput
        placeholder="Votre Message"
        style={{
          background: "#F2F2F2",
          margin: "38px 34px 0 34px",
          width: "97%",
        }}
        multiline
        rows={10}
        value={commentaire}
        onChange={(e) => setcommentaire(e.target.value)}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "end",
          marginRight: "50px",
          width: "100%",
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{ marginRight: "50px", marginTop: "20px" }}
          onClick={handleSubmit(onValideContact)}
        >
          Envoyer
        </Button>
      </div>
    </div>
  );
};

export default Contact;
