import React from "react";

import { Button, Typography } from "@mui/material";
import { useParams } from "react-router-dom";

import { useStyles } from "./style";

interface ICardHistorique {
  field: string;
  numero: number;
  date: string;
  status?: string;
  statusColor?: string;
  onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
  price?: { label: string; value: string }[];
  isValidated?: boolean;
  enDate?: string;
}
const CardHistorique: React.FC<ICardHistorique> = (props) => {
  const { activ } = useParams();
  const {
    field,
    numero,
    date,
    price,
    status,
    statusColor = "red",
    onClick,
    isValidated,
    enDate,
  } = props;
  const style = useStyles();
  const newField = activ === "1" ? "Plannification Commande" : field;
  return (
    <div className={style.container}>
      <div className={style.root}>
        <Typography style={{ fontSize: "20px", fontWeight: "600" }}>
          {`${newField} : ${numero}`}
        </Typography>
        <div className={style.headerBox}>
          <div className={style.boxDateCreated}>
            <Typography
              style={{
                fontSize: "16px",
                font: "normal normal medium 16px/21px Roboto",
                color: "#909090",
              }}
            >
              Date du création
            </Typography>
            <Typography style={{ fontSize: "16px" }}>{date}</Typography>
          </div>
          {price && (
            <div className={style.boxPrice}>
              <Typography
                style={{
                  fontSize: "16px",
                  font: "normal normal medium 16px/21px Roboto",
                  color: "#909090",
                }}
              >
                prix:
              </Typography>
              {price.map((item) => {
                return (
                  <div
                    key={item.label}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      width: "168px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography color="#909090">{item.label}:</Typography>
                    <Typography>{item.value}</Typography>
                  </div>
                );
              })}
            </div>
          )}
          <div className={style.boxStatus}>
            {status && (
              <Typography style={{ color: "#909090", fontSize: "16px" }}>
                Statut
              </Typography>
            )}
            {status && <Typography color={statusColor}>{status}</Typography>}
            {activ === "2" && (
              <>
                <Typography style={{ color: "#909090", fontSize: "16px" }}>
                  Statut du devis
                </Typography>
                <Typography
                  style={{
                    color: isValidated ? "#17A559" : "#FE3434",
                  }}
                >
                  {isValidated ? "validé" : "À valider"}
                </Typography>
              </>
            )}
            {activ === "1" && enDate && (
              <>
                <Typography style={{ color: "#909090", fontSize: "16px" }}>
                  Date de fin
                </Typography>
                <Typography>{enDate}</Typography>
              </>
            )}
          </div>
        </div>
      </div>

      <div style={{ width: "25%" }}>
        <Button
          style={{
            fontSize: "16px",
            color: "#909090",
            textTransform: "unset",
          }}
          onClick={onClick}
        >
          Voir détails
        </Button>
      </div>
    </div>
  );
};

export default CardHistorique;
