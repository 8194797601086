import { makeStyles } from "@mui/styles";

export const useStyle = makeStyles({
  container: {
    padding: "10px 20px 15px 18px",
    minHeight: "316px",
    width: "216px",
    background: "rgb(0 0 0 / 4%)",
    borderRadius: "17px",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "space-between",
    margin: "0 10px 0 0",
  },
  rootDetailsPersonnal: { margin: "32px 0 0 0px" },
  rootPrice: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  rootFooter: {
    background: "#22A55B",
    width: "100%",
    padding: "10px 0 10px 0",
  },
});

export const stylesX = {
  colorTextNumber: { color: "#00000050" },
  name: {
    color: "#056542",
    fontSize: 18,
    fontWeight: "bold",
  },
  price: { color: "#056542" },
  colorStatus: { color: "#fff" },
  typeStatus: { color: "#fff", fontWeight: "bold" },
};
