import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles({
  container: {
    display: "flex",
    alignItems: "center",
    padding: "10px 21px 10px",
    flexDirection: "row",
  },
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    flex: "1",
    justifyContent: "space-between",
  },
  headerBox: {
    display: "flex",
    alignItems: "center",
    flex: "1",
    width: "100%",
  },
  boxDateCreated: {
    margin: "0px 50px 0px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
    alignSelf: "flex-start",
  },
  boxPrice: {
    margin: "0px 62px 0px 0px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-start",
  },
  boxStatus: {
    display: "flex",
    alignSelf: "flex-start",
    flexDirection: "column",
  },
});
