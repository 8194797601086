import React from "react";

import { Typography } from "@mui/material";

interface ICustomField {
  label: string;
  value: string;
}
const CustomField: React.FC<ICustomField> = ({ label, value }) => {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        width: 350,
      }}
    >
      <Typography
        style={{
          color: "#909090",
          fontSize: "16px",
          letterSpacing: "-0.32px",
        }}
      >
        {label}
      </Typography>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "start",
        }}
      >
        {value.split("**").map((text) => {
          return (
            <Typography
              style={{
                color: "rgb(0, 0, 0)",
                fontSize: "11px",
                textAlign: "start",
                width: "300px",
                textTransform: "unset",
              }}
            >
              {text}
            </Typography>
          );
        })}
      </div>
    </div>
  );
};

export default CustomField;
