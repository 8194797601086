import { makeStyles } from "@mui/styles";

export const useNewStyle = makeStyles({
  container: {
    flex: 1,
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px -12px 6px #00000029",
    borderRadius: "42px 0px 0px 0px",
    width: "100%",
    padding: "42px 0 0 0",
  },
  rootTopBox: {
    display: "flex",
    flexDirection: "row",
    padding: "0 0 0 40px",
    alignItems: "center",
  },
  boxSelect: {
    display: "flex",
    flexDirection: "row",
    margin: "0 30px 0 0",
  },
  rootSelect: {
    background: "#FFFFFF 0% 0% no-repeat padding-box",
    boxShadow: "0px 3px 6px #00000029",
    width: "198px",
    height: "47px",
    marginRight: "28px",
  },
  inputGalenique: {
    width: "269px",
    border: "1px solid #FFE6E6",
    background: "#00000029",
    borderRadius: "6px",
  },
  boxCard: {
    display: "flex",
    padding: "40px",
  },
  rootCard: {
    margin: "0 24px 0 0",
  },
  inputDrag: {
    width: "506px",
    height: "258px",

    borderRadius: "16px",
    boxShadow: "10px 10px 5px 0px rgba(0,0,0,0.33) inset",
    MozBoxShadow: "10px 10px 5px 0px rgba(0,0,0,0.33) inset",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  img: {
    width: "488px",
    height: "245px",
    objectFit: "cover",
    borderRadius: "16px",
  },
});
